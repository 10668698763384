import React from "react";
import illustration from "../../../res/element/robot.OberionIO.min.svg";
import * as PropTypes from "prop-types";

const txt = [
  "Loading your website",
  "What game?",
];

const LoadingSpinner = ({ loading }) => {
  let loadingOrWaitingForGame;
  if (loading) {
    loadingOrWaitingForGame = (
      <>
        <h1 className="loading-spinner__h1">{txt[0]}</h1>
        <div className="loading-spinner" />
      </>
    );
  } else loadingOrWaitingForGame = <h1 className="loading-spinner__h1">{txt[1]}</h1>;

  return (
    <div className="loading-spinner__container">
      {loadingOrWaitingForGame}
      <img src={illustration}
           alt="Loading Illustration"
           className="loading-spinner__illustration" />
    </div>
  );
};
LoadingSpinner.defaultProps = {
  loading: false,
};
LoadingSpinner.propTypes = {
  loading: PropTypes.bool,
};
export default LoadingSpinner;