import React, { useEffect, useState } from "react";
import { requestWebsiteDataPreview } from "../components/rest/request-website-data";
import LoadingSpinner from "../components/preview/LoadingSpinner";
import Error from "../components/Error";
import Layout from "../components/Layout";
import _get from "lodash/get";


// http://localhost:8001/?url=https://store.steampowered.com/app/237930/Transistor/&template=16bit

const Index = ({ location }) => {
  let urlParams = new URLSearchParams(location.search);
  const url = urlParams.get("url");
  const template = urlParams.get("template");

  if (url == null || (url.length === 0)) {
    return <LoadingSpinner />;
  }

  const [websiteData, setWebsiteData] = useState(null);
  const [hasError, setError] = useState(false);
  const [isLoadingFinished, setLoadingFinished] = useState(false);

  /* --- Update website when url changes --- */
  useEffect(() => {
    if (isLoadingFinished === true) setLoadingFinished(false); // when loading has finished before
    if (hasError === true) setError(false); // when error occurred before

    const getData = async () => {
      const res = await requestWebsiteDataPreview(url);
      const resWebsiteData = _get(res, "data.websiteData", null);
      if (resWebsiteData === null) {
        setError(true);
        setLoadingFinished(true);

      } else {
        setWebsiteData(resWebsiteData);
        setLoadingFinished(true);
      }
    };
    // noinspection JSIgnoredPromiseFromCall => useEffect should not return a promise
    getData();
  }, [url]);

  /* --- Update website when user changes template --- */
  // useEffect(() => {
  //   const doSomething = "Do something when template changes";
  // }, [template]);

  /* --- Conditionally render correct component --- */
  if (hasError) {
    return <Error />;
  } else if (isLoadingFinished) {
    return <Layout websiteData={websiteData} />;
  } else {
    return <LoadingSpinner loading />;
  }
};
/* --- DELETE GRAPHQL --- */

export default Index;