import ky from "ky";
import { logError } from "../element/error/sentry";
import endpoint from "./endpoint";

export const requestWebsiteDataPreview = async (url) => {
  const requestOptions = {
    json: {
      url: url,
      key: process.env.GATSBY_OBERION_PREVIEW_PUBLIC_KEY,
    },
  };
  try {
    return ky.post(endpoint.previewWebsiteData, requestOptions).json();
  } catch (error) {
    let body = {};
    if (error instanceof ky.HTTPError) body = await error.response.json();
    logError(error, { ...requestOptions.json, ...body });
    return null;
  }
};