import React from "react";
import * as PropTypes from "prop-types";


const Footer = ({ legalNotice, supportInfo }) => {
  return (
    <footer className="footer">
      {supportInfo && (
        <>
          <h2>Say Hello!</h2>
          <p>For business inquiries please contact</p>
          <p>{supportInfo.email}</p>
        </>
      )}
      {legalNotice && <p>{legalNotice}</p>}
      <AttributionLink />
    </footer>
  );
};

const attrTxt = ["Built with ", "https://oberion.io", "Oberion"];
const AttributionLink = () => (
  <p className="footer__attribution">
    {attrTxt[0]}
    <a href={attrTxt[1]} rel="noopener">{attrTxt[2]}</a>
  </p>
);

Footer.propTypes = {
  legalNotice: PropTypes.string,
  supportInfo: PropTypes.object,
};
export default Footer;